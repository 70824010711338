// app/javascript/packs/nested-forms/addFields.js
class addFields {
  constructor() {
    this.links = document.querySelectorAll(".add_fields");
    this.iterateLinks();
  }

  iterateLinks() {
    if (this.links.length === 0) return;
    this.links.forEach((link) => {
      link.addEventListener("click", (e) => {
        this.handleClick(link, e);
      });
    });
  }

  handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();
    let time      = new Date().getTime();
    let linkId    = link.dataset.id;
    let regexp    = linkId ? new RegExp(linkId, "g") : null;
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    var week_day  = link.dataset.dayWeek
    let week_count   = $(".nested-fields." + week_day).length
    if (week_count >= 1){
      $(".add_fields.add-btn." + week_day).hide();
    }
    newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;
    $(function() {
      var pickers = $('.timepicker');

      pickers.each(function(idx){
        $(pickers[idx]).timepicker();
      });

      if(!document.hidden){
        $(document).foundation();
      }
    });

    $('.open, .close').blur(function(){    
      var day_week = this.classList[4];
      var old_open_time = moment($('.open.' + day_week).val(), "h:mm A").format("HH:mm");
      var old_close_time = moment($('.close.'+ day_week).val(), "h:mm A").format("HH:mm");
      if ($('.close.'+ day_week).length == 3 && $('.open.'+ day_week).length == 3){
        var new_open_time = moment($('.open.' + day_week).eq(2).val(), "h:mm A").format("HH:mm");
        var new_close_time = moment($('.close.'+ day_week).eq(2).val(), "h:mm A").format("HH:mm");
      }else {
        var new_open_time = moment($('.open.' + day_week).eq(1).val(), "h:mm A").format("HH:mm");
        var new_close_time = moment($('.close.'+ day_week).eq(1).val(), "h:mm A").format("HH:mm");
      }
      if (new_open_time <= old_close_time &&  new_close_time >= old_open_time){
        $('.hours-overlap.' + day_week).html("this new operating hours overlaps with above operating hours");
      }else{
        $('.hours-overlap.' + day_week).html("");
      }
    });
  }
}

window.addEventListener("turbolinks:load", () => new addFields());
