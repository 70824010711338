// app/javascript/packs/sanity_image_url.js
// import sanityClient from '@sanity/client';
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
// import { sanityClient } from '@sanity/client/umd/sanityClient.js';

// console.log('createClient', createClient);

const client = createClient({
  projectId: '71o6rglv', // Replace with your Sanity project ID
  dataset: 'production', // Replace with your dataset
  apiVersion: '2021-10-21',
  useCdn: true
});
// console.log('client', client);

const builder = imageUrlBuilder(client);
// console.log('builder', builder);

export function urlFor(source) {
  // console.log('source is');
  // console.log(source);
  return builder.image(source).url();
}
